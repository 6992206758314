@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  line-height: 1.6;
  font-size: 16px;
  background-color: #e5e5e5;
  /* 10px = 0.625rem
  12px = 0.75rem
  14px = 0.875rem
  16px = 1rem (base)
  18px = 1.125rem
  20px = 1.25rem
  24px = 1.5rem
  30px = 1.875rem
  32px = 2rem
  34px = 2.125rem 
  40px = 2.5rem */
}

@media (max-width: 960px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  html {
    font-size: 10px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: #1f4739 !important;
  color: #ffffff !important;
  font-family: Inter;
  font-weight: 600;
}
.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: #1f4739 !important;
}
.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  color: #1f4739 !important;
}
.uppy-Dashboard-Item-progressIcon--circle > circle {
  fill: #1f4739 !important;
}

/* IntroJs styles */
.fullscreen.introjs-tooltip {
  max-width: 500px;
  min-width: 500px;
  margin-left: 0 !important;
  margin-top: 0 !important;
  transform: translateX(-50%) translateY(-50%);
}

.fullscreen .introjs-arrow {
  display: none;
}
.fullscreen  .introjs-tooltiptext {
  padding-bottom: 10px;
}

.fullscreen .introjs-bullets {
  position: absolute;
  left: 50%;
  width: 50%;
  bottom: 20px;
  transform: translateX(-100%);
}
.fullscreen .introjs-skipbutton {
  display: none;
}

.fullscreen.introjs-tooltip img {
  max-width: 100%;
  min-height: 230px;
}

.fullscreen .introjs-tooltipbuttons {
  display: flex;
  justify-content: flex-end;
  border-top: none;
  padding: 20px;
}

.customTooltip.fullscreen .introjs-prevbutton {
  margin-right: 10px;
}

.customTooltip.customTooltip > .introjs-tooltipbuttons > a,
.customTooltip > .introjs-tooltiptext > .introjs-button {
  margin-left: 0;
}

.customTooltip > .introjs-tooltip-header {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 20px !important;
  color: #1f4739 !important;
}
.customTooltip > .introjs-tooltiptext > p {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #262626 !important;
}
.customTooltip > .introjs-tooltipbuttons > a,
.customTooltip > .introjs-tooltiptext > .introjs-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  width: 69px;
  height: 40px;
  background: #f0f5f3 !important;
  border-radius: 2px;
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1f4739 !important;
  border: none !important;
  margin-left: auto;
}
.customTooltip > .introjs-bullets a.active {
  background: #1f4739 !important;
}
.customTooltip > .introjs-bullets a {
  background: #a6bfb6 !important;
  width: 8px;
  height: 8px;
}
.introjs-helperLayer {
  box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px 0px, rgba(33, 33, 33, 0.8) 0px 0px 0px 5000px !important;
}

.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: 0 0;
  width: 20px;
  height: 15px;
  cursor: pointer;
  z-index: 1000000000;
}
.introjs-hint:focus {
  border: 0;
  outline: 0;
}
.introjs-hint:hover > .introjs-hint-pulse {
  background-color: #28c366;
}
.introjs-hidehint {
  display: none;
}
.introjs-fixedhint {
  position: fixed;
}
@keyframes introjspulseCustom {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #b8f9d2;
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px #b8f9d2;
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 transparent;
  }
}
.introjs-hint-pulse {
  box-sizing: content-box;
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background-color: #28c366;
  z-index: 10;
  position: absolute;
  transition: all 0.2s ease-out;
  animation: introjspulseCustom 2s infinite !important;
}
.introjs-hint-no-anim .introjs-hint-pulse {
  animation: none;
}
.introjs-hint-dot {
  box-sizing: content-box;
  background: 0 0;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  position: absolute;
  top: -9px;
  left: -9px;
  z-index: 1;
  opacity: 0;
}
/* End IntroJs styles */

/* HTML: <div class="loader"></div> */
.loader3D {
  width: 90px;
  height: 14px;
  box-shadow: 0 3px 0 #f0f5f3;
  position: relative;
  clip-path: inset(-40px 0 -5px);
}

.loader3D:before {
  content: '';
  position: absolute;
  inset: auto calc(50% - 17px) 0;
  height: 50px;
  --g: no-repeat linear-gradient(#a6bfb6 0 0);
  background: var(--g), var(--g), var(--g), var(--g);
  background-size: 16px 14px;
  animation: l7-1 2s infinite linear, l7-2 2s infinite linear;
}

@keyframes l7-1 {
  0%,
  100% {
    background-position: 0 -50px, 100% -50px;
  }

  17.5% {
    background-position: 0 100%, 100% -50px, 0 -50px, 100% -50px;
  }

  35% {
    background-position: 0 100%, 100% 100%, 0 -50px, 100% -50px;
  }

  52.5% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% -50px;
  }

  70%,
  98% {
    background-position: 0 100%, 100% 100%, 0 calc(100% - 16px), 100% calc(100% - 16px);
  }
}

@keyframes l7-2 {
  0%,
  70% {
    transform: translate(0);
  }

  100% {
    transform: translate(200%);
  }
}

.selected-table-row {
  background-color: #F0F5F3;
}

 .MuiDataGrid-panel .MuiFormControlLabel-label {
   font-size: 14px;
   color: rgb(38, 38, 38)
 }